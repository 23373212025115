import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import DealModel from "../../viewModels/Deal/DealModel";
import { DealsFiltersType } from "../../components/V2Components/DealFilters";

const pageSizeLsKey = "deals_table_pageSize";
const pageSizeFromLs = localStorage.getItem(pageSizeLsKey);

const initialState: {
  loading: boolean;
  loadingData: boolean;
  page: number;
  pageSize: number;
  deals?: Array<DealModel>;
  total?: number;
  filters?: DealsFiltersType;
} = {
  loading: true,
  loadingData: false,
  page: undefined!,
  pageSize: pageSizeFromLs ? +pageSizeFromLs : 10,
};

const dealsSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    loadDeals(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadDealsSuccess(state, action: PayloadAction<{ deals?: Array<DealModel>; total: number }>) {
      const { deals, total } = action.payload;

      state.loading = false;
      state.loadingData = false;
      state.deals = deals;
      state.total = total;
    },

    changeTable(state, action: PayloadAction<{ page: number; pageSize: number; filters: DealsFiltersType }>) {
      const { page, pageSize, filters } = action.payload;
      state.loadingData = true;
      state.page = page;
      state.pageSize = pageSize;
      localStorage.setItem(pageSizeLsKey, `${pageSize}`);
      state.filters = filters;
    },

    updateDeal(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<DealModel>,
    ) {
      state.loadingData = true;
    },
    updateDealSuccess(state, action: PayloadAction<DealModel>) {
      state.loadingData = false;
      const index = state.deals?.findIndex((x) => x.id === action.payload.id);
      if (index && state.deals) {
        state.deals[index] = action.payload;
      }
    },
  },
});

export default dealsSlice;
