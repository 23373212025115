import React, { useEffect } from "react";

import { Form, Button } from "antd";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { MailOutlined } from "@ant-design/icons";
import { RestorePasswordFormProps, RestorePasswordFormInputModel } from "./types";

import { makeSelectRestorePasswordFormErrors } from "../../../../selectors";
import AnimatedInput from "../../../../../../components/AnimatedInput";

function RestorePasswordForm({ loadingData, onRestorePassword }: RestorePasswordFormProps) {
  const { t } = useTranslation("translations");

  const [form] = Form.useForm();

  const formErrors = useSelector(makeSelectRestorePasswordFormErrors());

  useEffect(() => {
    if (formErrors) {
      const errors = [];

      if (formErrors.find((x) => x.field === "email" && x.errorCode === "2")) {
        errors.push({
          name: "login",
          errors: [t("identification_number_already_registered")],
        });
      }

      form.setFields(errors);
    }
  }, [formErrors, form, t]);

  const onFinish = ({ email }: RestorePasswordFormInputModel) => {
    onRestorePassword({ email });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: t("enter_email")! },
          { type: "email", message: t("wrong_email")! },
        ]}
      >
        <AnimatedInput placeholder="Email" prefixIcon={<MailOutlined />} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loadingData}>
          {t("send")}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default React.memo(RestorePasswordForm);
