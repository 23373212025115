import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import DealModel, { DealAccountModel } from "../../viewModels/Deal/DealModel";
import PaymentModel from "../../viewModels/Payments/PaymentModel";
import SortObject from "../../types/SortObject";
import { PaymentsFiltersType } from "../../components/V2Components/PaymentFilters";
import AccountPaymentModel, { AccountPaymentCreateType } from "../../viewModels/Deal/AccountPaymentModel";
import { AccountClosingDocumentCreateType } from "../../viewModels/Deal/AccountClosingDocumentModel";

const initialState: {
  loading: boolean;
  loadingData: boolean;

  deal: DealModel;
  paymentsInAccounts?: Array<PaymentModel>;

  selectedAccountId?: number;

  attachPaymentModalOpen: boolean;
  paymentsLoading: boolean;
  paymentsLoadingData: boolean;
  paymentsPage: number;
  paymentsPageSize: number;
  payments?: Array<PaymentModel>;
  paymentsTotal?: number;
  paymentsSort?: SortObject;
  paymentsFilters?: PaymentsFiltersType;
  selectedPaymentId?: number;
  attachPaymentLoading: boolean;
} = {
  loading: true,
  loadingData: true,

  deal: undefined!,

  attachPaymentModalOpen: false,
  paymentsLoading: true,
  paymentsLoadingData: true,
  paymentsPage: undefined!,
  paymentsPageSize: 10,
  attachPaymentLoading: false,
};

const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    loadDeal(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<number>,
    ) {
      state.loadingData = true;
    },
    loadDealSuccess(
      state,
      action: PayloadAction<{
        deal: DealModel;
        payments: Array<PaymentModel>;
      }>,
    ) {
      const { deal, payments } = action.payload;
      state.loading = false;
      state.loadingData = false;
      state.deal = deal;
      state.paymentsInAccounts = payments;
    },
    changeDeal(state, action: PayloadAction<DealModel>) {
      state.deal = action.payload;
    },

    resetState: () => initialState,

    updateDeal(state) {
      state.loadingData = true;
    },
    updateDealSuccess(state, action: PayloadAction<DealModel>) {
      state.loadingData = false;
      state.deal = action.payload;
    },

    createDocument(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<number>,
    ) {
      state.loadingData = true;
    },
    createDocumentSuccess(state) {
      state.loadingData = false;
    },

    createRevenueExpenseDocument(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{ id: number }>,
    ) {
      state.loadingData = true;
    },
    createRevenueExpenseDocumentSuccess(state) {
      state.loadingData = false;
    },

    openAttachPaymentModal(state, action: PayloadAction<{ filters: PaymentsFiltersType; accountId: number }>) {
      state.attachPaymentModalOpen = true;
      state.paymentsLoading = true;
      state.paymentsLoadingData = true;
      state.paymentsPage = 1;
      state.paymentsFilters = action.payload.filters;
      state.selectedAccountId = action.payload.accountId;
    },

    closeAttachPaymentModal(state) {
      if (!state.attachPaymentLoading) {
        state.attachPaymentModalOpen = false;
        state.payments = undefined;
        state.paymentsTotal = undefined;
        state.selectedAccountId = undefined;
        state.paymentsFilters = undefined;
        state.selectedPaymentId = undefined;
      }
    },

    loadPaymentsSuccess(state, action: PayloadAction<{ payments: Array<PaymentModel>; total: number }>) {
      const { payments, total } = action.payload;

      state.paymentsLoading = false;
      state.paymentsLoadingData = false;
      state.payments = payments;
      state.paymentsTotal = total;
    },

    changePaymentsTable(state, action: PayloadAction<{ page: number; pageSize: number; sort?: SortObject; filters: PaymentsFiltersType }>) {
      const { page, pageSize, sort, filters } = action.payload;
      state.paymentsLoadingData = true;
      state.paymentsPage = page;
      state.paymentsPageSize = pageSize;
      state.paymentsSort = sort;
      state.paymentsFilters = filters;
      state.selectedPaymentId = undefined;
    },

    changeSelectedPaymentId(state, action: PayloadAction<number | undefined>) {
      state.selectedPaymentId = action.payload;
    },

    // eslint-disable-next-line no-unused-vars
    attachPayment(state, action: PayloadAction<AccountPaymentCreateType>) {
      state.attachPaymentLoading = true;
    },

    attachPaymentSuccess(state, action: PayloadAction<{ payment: PaymentModel; account: DealAccountModel }>) {
      const { account, payment } = action.payload;

      state.attachPaymentLoading = false;
      state.attachPaymentModalOpen = false;
      state.payments = undefined;
      state.paymentsTotal = undefined;
      state.selectedAccountId = undefined;
      state.paymentsFilters = undefined;
      state.selectedPaymentId = undefined;

      if (state.deal.revenue) {
        const index = state.deal.revenue.findIndex((x) => x.id === account.id);
        if (index !== -1) {
          state.deal.revenue[index] = account;
        }
      }

      if (state.deal.expense) {
        const index = state.deal.expense.findIndex((x) => x.id === account.id);
        if (index !== -1) {
          state.deal.expense[index] = account;
        }
      }

      if (state.paymentsInAccounts) {
        const index = state.paymentsInAccounts.findIndex((x) => x.id === payment.id);
        if (index !== -1) {
          state.paymentsInAccounts[index] = payment;
        } else {
          state.paymentsInAccounts.push(payment);
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    deleteAccountPayment(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },

    deleteAccountPaymentSuccess(state, action: PayloadAction<{ payment: PaymentModel; account: DealAccountModel }>) {
      const { account, payment } = action.payload;
      state.loadingData = false;

      if (state.deal.revenue) {
        const index = state.deal.revenue.findIndex((x) => x.id === account.id);
        if (index !== -1) {
          state.deal.revenue[index] = account;
        }
      }

      if (state.deal.expense) {
        const index = state.deal.expense.findIndex((x) => x.id === account.id);
        if (index !== -1) {
          state.deal.expense[index] = account;
        }
      }

      if (state.paymentsInAccounts) {
        const index = state.paymentsInAccounts.findIndex((x) => x.id === payment.id);
        if (index !== -1) {
          state.paymentsInAccounts[index] = payment;
        } else {
          state.paymentsInAccounts.push(payment);
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    editAccountPayment(state, action: PayloadAction<AccountPaymentModel>) {},

    editAccountPaymentSuccess(state, action: PayloadAction<{ payment: PaymentModel; account: DealAccountModel }>) {
      const { account, payment } = action.payload;

      if (state.deal.revenue) {
        const index = state.deal.revenue.findIndex((x) => x.id === account.id);
        if (index !== -1) {
          state.deal.revenue[index] = account;
        }
      }

      if (state.deal.expense) {
        const index = state.deal.expense.findIndex((x) => x.id === account.id);
        if (index !== -1) {
          state.deal.expense[index] = account;
        }
      }

      if (state.paymentsInAccounts) {
        const index = state.paymentsInAccounts.findIndex((x) => x.id === payment.id);
        if (index !== -1) {
          state.paymentsInAccounts[index] = payment;
        } else {
          state.paymentsInAccounts.push(payment);
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    createAccountClosingDocuments(state, action: PayloadAction<AccountClosingDocumentCreateType>) {},

    createAccountClosingDocumentsSuccess(state, action: PayloadAction<DealAccountModel>) {
      if (state.deal.revenue) {
        const index = state.deal.revenue.findIndex((x) => x.id === action.payload.id);
        if (index !== -1) {
          state.deal.revenue[index] = action.payload;
        }
      }

      if (state.deal.expense) {
        const index = state.deal.expense.findIndex((x) => x.id === action.payload.id);
        if (index !== -1) {
          state.deal.expense[index] = action.payload;
        }
      }
    },
  },
});

export default dealSlice;
