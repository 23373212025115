import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: {
  loadingData: boolean;
} = {
  loadingData: false,
};

const logisticianHomeSlice = createSlice({
  name: "logisticianHome",
  initialState,
  reducers: {
    createFtlApplication(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{
        fromLocationId: number;
        waypointId?: number;
        toLocationId: number;
        date: string;
        transportCharacteristicId: number;
        count: number;
        cost: number;
        currencyId: number;
      }>,
    ) {
      state.loadingData = true;
    },
    createFclApplication(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{
        fromLocationId: number;
        waypointId?: number;
        toLocationId: number;
        date: string;
        transportCharacteristicId: number;
        transportCharacteristicValue: string;
        count: number;
        cost: number;
        currencyId: number;
      }>,
    ) {
      state.loadingData = true;
    },
    createApplication(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{
        transportCharacteristicId: number;
      }>,
    ) {
      state.loadingData = true;
    },
  },
});

export default logisticianHomeSlice;
