import React, { useCallback, useState } from "react";

import { Button } from "antd";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { AuthActions } from "../../../../auth/actions";

import useTimer from "../../../../hooks/useTimer";

import { Text } from "../../../../components/Typegraphy/Text";

function SuccessRestorePasswordView() {
  const [version, setVersion] = useState(0);
  const { t } = useTranslation("translations");
  const dispatch = useDispatch();

  const { minutes, seconds, secondsLeft } = useTimer(60, version);

  const handleResendRestorePassword = useCallback(() => {
    dispatch(AuthActions.resendRestorePassword());
    setVersion((x) => x + 1);
  }, [dispatch]);

  return (
    <>
      <Text margin={secondsLeft > 0 ? "0 0 20px 0" : "0 0 64px 0"}>{t("drop_password_email_sent")}</Text>
      {secondsLeft > 0 && (
        <Text color="gray-600" margin="0 0 20px">
          <>
            {secondsLeft > 0 && t("resending_possible_after")} {minutes}:{seconds}
          </>
        </Text>
      )}
      <Button type="text" onClick={handleResendRestorePassword} disabled={secondsLeft > 0}>
        {t("send_email_again")}
      </Button>
    </>
  );
}

export default React.memo(SuccessRestorePasswordView);
