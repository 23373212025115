import { call } from "redux-saga/effects";

import { ApiService, dictionaryApiService, authApiService, notificationApiService } from "../../../../api/actionService/apiService";

import { ApiCallResult } from "../../../../api/types";

import { DICTIONARY_API_SETTINGS_CONTROLLER_END_POINTS } from "../../../../config/apiEndPoints/DictionaryApiEndsPoints/SettingsController";
import { AUTH_API_CONTROLLER_END_POINTS } from "../../../../config/apiEndPoints/AuthApiEndPoints/AuthController";
import { NOTIFICATION_API_FEEDBACK_CONTROLLER_END_POINTS } from "../../../../config/apiEndPoints/NotificationApiEndPoints/FeedbackController";
import { AdditionalJobModel } from "../../../../viewModels/Settings/AdditionalJobModel";
import { DICTIONARY_API_ADDITIONAL_JOBS_CONTROLLER_END_POINTS } from "../../../../config/apiEndPoints/DictionaryApiEndsPoints/AdditionalJobsController";
import { DICTIONARY_API_LOCATIONS_CONTROLLER_END_POINTS } from "../../../../config/apiEndPoints/DictionaryApiEndsPoints/LocationsController";
import { DICTIONARY_API_COUNTRIES_CONTROLLER_END_POINTS } from "../../../../config/apiEndPoints/DictionaryApiEndsPoints/CountriesController";
import { DICTIONARY_API_APPLICATION_SERVICES_CONTROLLER_END_POINTS } from "../../../../config/apiEndPoints/DictionaryApiEndsPoints/ApplicationServicesController";
import { DICTIONARY_API_CARRIERS_CONTROLLER_END_POINTS } from "../../../../config/apiEndPoints/DictionaryApiEndsPoints/CarriersController";
import { AUTH_API_COMPANIES_CONTROLLER_END_POINTS } from "../../../../config/apiEndPoints/AuthApiEndPoints/CompaniesController";
import { DICTIONARY_API_CURRENCIES_CONTROLLER_END_POINTS } from "../../../../config/apiEndPoints/DictionaryApiEndsPoints/CurrenciesController";

const dictionaryController = DICTIONARY_API_SETTINGS_CONTROLLER_END_POINTS.CONTROLLER_PATH;
const authController = AUTH_API_CONTROLLER_END_POINTS.CONTROLLER_PATH;
const notificationFeedbackController = NOTIFICATION_API_FEEDBACK_CONTROLLER_END_POINTS.CONTROLLER_PATH;
const countriesController = DICTIONARY_API_COUNTRIES_CONTROLLER_END_POINTS.CONTROLLER_PATH;

const feedbackPath = `${notificationFeedbackController}${NOTIFICATION_API_FEEDBACK_CONTROLLER_END_POINTS.FEEDBACK_PATH}`;

const additionalJobsPath = DICTIONARY_API_ADDITIONAL_JOBS_CONTROLLER_END_POINTS.CONTROLLER_PATH;

const locationsPath = `${DICTIONARY_API_LOCATIONS_CONTROLLER_END_POINTS.CONTROLLER_PATH}${DICTIONARY_API_LOCATIONS_CONTROLLER_END_POINTS.BASIC_INFO}`;
const countriesPath = `${countriesController}${DICTIONARY_API_COUNTRIES_CONTROLLER_END_POINTS.BASIC_INFO_PATH}`;

const applicationServicesPath = `${DICTIONARY_API_APPLICATION_SERVICES_CONTROLLER_END_POINTS.CONTROLLER_PATH}`;

const loginBitrixPath = `${authController}${AUTH_API_CONTROLLER_END_POINTS.LOGIN_BITRIX}`;

const carriersController = DICTIONARY_API_CARRIERS_CONTROLLER_END_POINTS.CONTROLLER_PATH;
const carriersBasicInfoPath = `${carriersController}${DICTIONARY_API_CARRIERS_CONTROLLER_END_POINTS.BASIC_INFO}`;
const bitrixCarriersBasicInfoPath = `${carriersController}${DICTIONARY_API_CARRIERS_CONTROLLER_END_POINTS.BITRIX_BASIC_INFO}`;

const companiesController = AUTH_API_COMPANIES_CONTROLLER_END_POINTS.CONTROLLER_PATH;
const settlementAccountsPath = companiesController + AUTH_API_COMPANIES_CONTROLLER_END_POINTS.SETTLEMENT_ACCOUNT_PATH;

const currenciesController = DICTIONARY_API_CURRENCIES_CONTROLLER_END_POINTS.CONTROLLER_PATH;

export class GlobalHttpService {
  private dictionaryApiService: ApiService;

  private authApiService: ApiService;

  private notificationApiService: ApiService;

  constructor(passedDictionaryApiService: ApiService, passedAuthApiService: ApiService, passedNotificationApiService: ApiService) {
    this.dictionaryApiService = passedDictionaryApiService;
    this.authApiService = passedAuthApiService;
    this.notificationApiService = passedNotificationApiService;
  }

  public *GetSettings() {
    return (yield call([this.dictionaryApiService, this.dictionaryApiService.Get], dictionaryController)) as ApiCallResult;
  }

  public *GetUser() {
    return (yield call([this.authApiService, this.authApiService.Get], authController)) as ApiCallResult;
  }

  public *SendFeedbackForm(fullname: string, phoneNumber: string, email: string, message: string) {
    return (yield call([this.notificationApiService, this.notificationApiService.Put], feedbackPath, undefined!, {
      fullname,
      phoneNumber,
      email,
      message,
    })) as ApiCallResult;
  }

  public *ChangeContainerIssuingAdditionalJobWatcher(containerIssuing: AdditionalJobModel) {
    return (yield call(
      [this.dictionaryApiService, this.dictionaryApiService.Patch],
      additionalJobsPath,
      containerIssuing.id,
      containerIssuing,
    )) as ApiCallResult;
  }

  public *LoadWaypointLocations() {
    return (yield call([this.dictionaryApiService, this.dictionaryApiService.Get], locationsPath, undefined!, {
      waypoint: true,
    })) as ApiCallResult;
  }

  public *LoadBorderCrossingLocations() {
    return (yield call([this.dictionaryApiService, this.dictionaryApiService.Get], locationsPath, undefined!, {
      "border-crossing": true,
    })) as ApiCallResult;
  }

  public *LoadCountries() {
    return (yield call([this.dictionaryApiService, this.dictionaryApiService.Get], countriesPath)) as ApiCallResult;
  }

  public *LoadApplicationServices() {
    return (yield call([this.dictionaryApiService, this.dictionaryApiService.Get], applicationServicesPath)) as ApiCallResult;
  }

  public *LoginBitrix(email: string) {
    return (yield call([this.authApiService, this.authApiService.Post], loginBitrixPath, {
      email,
      apiKey: process.env.REACT_APP_BITRIX_API_KEY,
    })) as ApiCallResult;
  }

  public *GetCarriers() {
    return (yield call([this.dictionaryApiService, this.dictionaryApiService.Get], carriersBasicInfoPath)) as ApiCallResult;
  }

  public *GetBitrixCarriers() {
    return (yield call([this.dictionaryApiService, this.dictionaryApiService.Get], bitrixCarriersBasicInfoPath)) as ApiCallResult;
  }

  public *GetSettlementAccounts() {
    return (yield call([authApiService, authApiService.Get], settlementAccountsPath)) as ApiCallResult;
  }

  public *GetCurrencies() {
    return (yield call([this.dictionaryApiService, this.dictionaryApiService.Get], currenciesController)) as ApiCallResult;
  }
}

export const globalHttpService = new GlobalHttpService(dictionaryApiService, authApiService, notificationApiService);
