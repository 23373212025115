import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import SortObject from "../../../types/SortObject";
import GroupedBonusModel, { BonusCreateType, BonusModel } from "../../../viewModels/Deal/BonusModel";
import { UserBasicInfoModel } from "../../../viewModels/User/UserBasicInfoModel";
import { BonusesFiltersType } from "../../../components/V2Components/BonusesFilters";

const initialState: {
  loading: boolean;
  loadingData: boolean;
  page: number;
  pageSize: number;
  bonuses?: Array<GroupedBonusModel>;
  total?: number;
  sort?: SortObject;
  filters?: BonusesFiltersType;

  workers?: Array<UserBasicInfoModel>;
} = {
  loading: true,
  loadingData: true,
  page: undefined!,
  pageSize: 20,
};

const accountantBonusesSlice = createSlice({
  name: "accountantBonuses",
  initialState,
  reducers: {
    loadBonuses(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadBonusesSuccess(state, action: PayloadAction<{ bonuses: Array<GroupedBonusModel>; total: number }>) {
      const { total, bonuses } = action.payload;

      state.loading = false;
      state.loadingData = false;
      state.bonuses = bonuses;
      state.total = total;
    },

    changeTable(state, action: PayloadAction<{ page: number; pageSize: number; sort?: SortObject; filters: BonusesFiltersType }>) {
      const { page, pageSize, sort, filters } = action.payload;
      state.loadingData = true;
      state.page = page;
      state.pageSize = pageSize;
      state.sort = sort;
      state.filters = filters;
    },

    setWorkers(state, action: PayloadAction<Array<UserBasicInfoModel>>) {
      state.workers = action.payload;
    },

    editBonus(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{ uuid: string; bonus: BonusModel }>,
    ) {
      state.loadingData = true;
    },
    editBonusSuccess(state, action: PayloadAction<{ uuid: string; bonus: BonusModel }>) {
      state.loadingData = false;

      if (state.bonuses) {
        const groupIndex = state.bonuses.findIndex((x) => x.uuid === action.payload.uuid);
        const index = state.bonuses[groupIndex].bonuses.findIndex((x) => x.id === action.payload.bonus.id);

        if (index !== undefined && index !== -1 && state.bonuses) {
          state.bonuses[groupIndex].bonuses[index] = action.payload.bonus;
        }
      }
    },

    createBonus(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<BonusCreateType>,
    ) {
      state.loadingData = true;
    },
  },
});

export default accountantBonusesSlice;
