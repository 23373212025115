import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import CommercialOfferModel from "../../viewModels/CommercialOffers/CommercialOfferModel";

const pageSizeLsKey = "commercial_offers_table_pageSize";
const pageSizeFromLs = localStorage.getItem(pageSizeLsKey);

const initialState: {
  loading: boolean;
  loadingData: boolean;
  page: number;
  pageSize: number;
  commercialOffers?: Array<CommercialOfferModel>;
  total?: number;
} = {
  loading: false,
  loadingData: false,

  page: undefined!,
  pageSize: pageSizeFromLs ? +pageSizeFromLs : 10,
};

const commercialOffersSlice = createSlice({
  name: "commercialOffers",
  initialState,
  reducers: {
    loadCommercialOffers(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadCommercialOffersSuccess(state, action: PayloadAction<{ commercialOffers?: Array<CommercialOfferModel>; total: number }>) {
      const { commercialOffers, total } = action.payload;

      state.loading = false;
      state.loadingData = false;
      state.commercialOffers = commercialOffers;
      state.total = total;
    },

    changeTable(state, action: PayloadAction<{ page: number; pageSize: number }>) {
      const { page, pageSize } = action.payload;
      state.loadingData = true;
      state.page = page;
      state.pageSize = pageSize;
      localStorage.setItem(pageSizeLsKey, `${pageSize}`);
    },

    updateCommercialOffer(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<Omit<CommercialOfferModel, "creationDate">>,
    ) {
      state.loadingData = true;
    },
    updateCommercialOfferSuccess(state, action: PayloadAction<CommercialOfferModel>) {
      state.loadingData = false;
      const index = state.commercialOffers?.findIndex((x) => x.id === action.payload.id);
      if (index && state.commercialOffers) {
        state.commercialOffers[index] = action.payload;
      }
    },
  },
});

export default commercialOffersSlice;
