import React, { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { AuthActions } from "../../../../auth/actions";

import { changeCurrentView } from "../../reducer";

import { makeSelectIsRegisterLoading } from "../../selectors";

import RegisterForm from "./components/RegisterForm";

import { RegisterInputModel } from "../../../../viewModels/Auth/RegisterInputModel";

function RegisterView() {
  const isRegisterLoading = useSelector(makeSelectIsRegisterLoading());
  const dispatch = useDispatch();

  const handleLogin = useCallback(
    (registerModel: RegisterInputModel) => {
      dispatch(AuthActions.register(registerModel));
    },
    [dispatch],
  );

  const handleOpenLoginView = useCallback(() => {
    dispatch(changeCurrentView("LOGIN_VIEW"));
  }, [dispatch]);

  return <RegisterForm onRegister={handleLogin} loadingData={isRegisterLoading} onOpenLoginView={handleOpenLoginView} />;
}

export default React.memo(RegisterView);
