import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApplicationModel, ApplicationModelUpdateType } from "../../viewModels/Applications/ApplicationModel";
import { UserBasicInfoModel } from "../../viewModels/User/UserBasicInfoModel";
import { ApplicationsFiltersType } from "../../components/V2Components/ApplicationFilters";
import { ApplicationStatus } from "../../dto/Applications/ApplicationDto";

const pageSizeLsKey = "applications_table_pageSize";
const pageSizeFromLs = localStorage.getItem(pageSizeLsKey);

const initialState: {
  loading: boolean;
  loadingData: boolean;
  page: number;
  pageSize: number;
  applications?: Array<ApplicationModel>;
  total?: number;
  filters?: ApplicationsFiltersType;

  managers?: Array<UserBasicInfoModel>;
  logisticians?: Array<UserBasicInfoModel>;
} = {
  loading: true,
  loadingData: true,

  page: undefined!,
  pageSize: pageSizeFromLs ? +pageSizeFromLs : 10,
};

const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    loadApplications(state, action: PayloadAction<ApplicationStatus | undefined>) {
      state.loadingData = true;
      state.page = 1;
      if (action.payload) {
        state.filters = {
          status: [action.payload],
          logistician: null,
          manager: null,
          type: null,
        };
      } else {
        state.filters = undefined;
      }
    },

    loadApplicationsSuccess(state, action: PayloadAction<{ applications?: Array<ApplicationModel>; total: number }>) {
      const { applications, total } = action.payload;

      state.loading = false;
      state.loadingData = false;
      state.applications = applications;
      state.total = total;
    },

    changeTable(state, action: PayloadAction<{ page: number; pageSize: number; filters: ApplicationsFiltersType }>) {
      const { page, pageSize, filters } = action.payload;

      state.loadingData = true;
      state.page = page;
      state.pageSize = pageSize;
      localStorage.setItem(pageSizeLsKey, `${pageSize}`);
      state.filters = filters;
    },

    updateApplication(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<ApplicationModelUpdateType>,
    ) {
      state.loadingData = true;
    },
    updateApplicationSuccess(state, action: PayloadAction<ApplicationModel>) {
      state.loadingData = false;
      const index = state.applications?.findIndex((x) => x.id === action.payload.id);
      if (index !== undefined && index !== -1 && state.applications) {
        state.applications[index] = action.payload;
      }
    },

    setManagers(state, action: PayloadAction<Array<UserBasicInfoModel>>) {
      state.managers = action.payload;
    },
    setLogisticians(state, action: PayloadAction<Array<UserBasicInfoModel>>) {
      state.logisticians = action.payload;
    },

    // eslint-disable-next-line no-unused-vars
    deleteApplication(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },

    // eslint-disable-next-line no-unused-vars
    copyApplication(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },

    resetState: () => initialState,
  },
});

export default applicationsSlice;
