import React, { useCallback } from "react";

import { Modal } from "antd";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import LoginView from "./components/LoginView";
import RestorePasswordView from "./components/RestorePasswordView";
import RegisterView from "./components/RegisterView";
import SuccessRegisterView from "./components/SuccessRegisterView";
import SucessRestorePasswordView from "./components/SucessRestorePasswordView";

import { makeSelectIsAuthModalVisible } from "../App/selectors";
import AppSlice from "../App/reducer";

import { makeSelectCurrentView } from "./selectors";
import exhaustiveCheck from "../../utils/exhaustiveCheck";

function AuthModal() {
  const dispatch = useDispatch();

  const { t } = useTranslation("translations");

  const currentView = useSelector(makeSelectCurrentView());
  const isAuthModalVisible = useSelector(makeSelectIsAuthModalVisible());

  const handleCloseAuthModal = useCallback(() => {
    dispatch(AppSlice.actions.closeAuthModal());
  }, [dispatch]);

  const getTitle = () => {
    switch (currentView) {
      case "LOGIN_VIEW":
        return t("login_to_account");
      case "REGISTER_VIEW":
        return t("registration");
      case "RESTORE_PASSWORD_VIEW":
        return t("password_restore");
      case "SUCCESS_RESTORE_PASSWORD_VIEW":
        return t("emall_sent");
      case "SUCCESS_REGISTER_VIEW":
        return t("emall_sent");
      default:
        return exhaustiveCheck(currentView);
    }
  };

  return (
    <Modal open={isAuthModalVisible} onCancel={handleCloseAuthModal} footer={null} destroyOnClose width={480} title={getTitle()}>
      {currentView === "LOGIN_VIEW" && <LoginView />}
      {currentView === "RESTORE_PASSWORD_VIEW" && <RestorePasswordView />}
      {currentView === "REGISTER_VIEW" && <RegisterView />}
      {currentView === "SUCCESS_REGISTER_VIEW" && <SuccessRegisterView />}
      {currentView === "SUCCESS_RESTORE_PASSWORD_VIEW" && <SucessRestorePasswordView />}
    </Modal>
  );
}

export default React.memo(AuthModal);
