import React, { useEffect } from "react";

import { Form, Button, Space, Typography } from "antd";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { NumericFormat } from "react-number-format";
import { makeSelectLoginFormErrors } from "../../../../selectors";

import { LoginFormProps, LoginFormInputModel } from "./types";
import AnimatedInput from "../../../../../../components/AnimatedInput";

function LoginForm({ loadingData, onLogin, onOpenRestorePasswordView, onOpenRegisterView }: LoginFormProps) {
  const { t } = useTranslation("translations");
  const [form] = Form.useForm();

  const formErrors = useSelector(makeSelectLoginFormErrors());

  useEffect(() => {
    if (formErrors) {
      const errors = [];

      if (formErrors.find((x) => x.field === "login" && x.errorCode === "2")) {
        errors.push({
          name: "login",
          errors: [t("user_with_such_identification_number_not_found")],
        });
      }

      if (formErrors.find((x) => x.field === "password" && x.errorCode === "4")) {
        errors.push({
          name: "password",
          errors: [t("wrong_password")],
        });
      }

      form.setFields(errors);
    }
  }, [formErrors, form, t]);

  const onFinish = ({ login, password }: LoginFormInputModel) => {
    onLogin({ login, password });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="login"
        rules={[
          () => ({
            validator(_, value: string) {
              if (value && value.length >= 10 && value.length <= 12) {
                return Promise.resolve();
              }
              if (!value) {
                return Promise.reject(t("enter_identification_number"));
              }

              return Promise.reject(t("identification_number_length"));
            },
          }),
        ]}
      >
        <NumericFormat
          placeholder={t("identification_number")}
          allowNegative={false}
          decimalScale={0}
          allowLeadingZeros
          customInput={AnimatedInput}
          prefixIcon={<UserOutlined />}
        />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: t("enter_password")! }]}>
        <AnimatedInput placeholder={t("password")!} type="password" prefixIcon={<LockOutlined />} />
      </Form.Item>
      <Form.Item>
        <Button type="text" onClick={onOpenRestorePasswordView} style={{ padding: 0, height: "auto" }}>
          {t("forgot_password")}
        </Button>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loadingData}>
          {t("enter")}
        </Button>
      </Form.Item>

      <Space align="center" style={{ width: "100%", justifyContent: "center" }}>
        <Typography.Text>{t("is_new_user")}</Typography.Text>
        <Button onClick={onOpenRegisterView} type="text">
          {t("register")}
        </Button>
      </Space>
    </Form>
  );
}

export default React.memo(LoginForm);
