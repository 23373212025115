import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import qs from "qs";

import { AuthActions } from "../../../auth/actions";

import { IGlobalState, MessageConfig } from "./types";

import { UserInfoModel } from "../../../viewModels/User/UserInfoModel";

import { GlobalSettingsModel, UserSettingModel } from "../../../viewModels/Settings/SettingsModel";
import { ContactFormInputModel } from "../../ContactModal/components/ContactForm/types";
import { AdditionalJobModel } from "../../../viewModels/Settings/AdditionalJobModel";
import { SupportedLanguages } from "../../../viewModels/Settings/TranslationModel";

// TODO придумать что-то как красивее парсить данные для интиализации
const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
const initOpts: { email: string; apiKey: string; leadId: number; companyId: number } | undefined = query.initOpts
  ? JSON.parse(query.initOpts as string)
  : undefined;

let host: "WEB" | "BITRIX" = "WEB";
let bitrixOptions;
if (initOpts && initOpts.apiKey === process.env.REACT_APP_BITRIX_API_KEY) {
  host = "BITRIX";
  bitrixOptions = {
    email: initOpts.email,
    leadId: initOpts.leadId,
    companyId: initOpts.companyId,
  };
}

const initialState: IGlobalState = {
  loading: true,

  initialLoadingError: false,

  userInfo: undefined!,

  isAuthModalVisible: false,

  isContactModalVisible: false,
  isContactModalLoading: false,

  // глобавльные данные
  globalCurrencies: undefined!,
  transportationTypes: undefined!,
  transportCharacteristics: undefined!,
  additionalJobs: undefined!,
  conditionDeliveries: undefined!,
  documentTypes: undefined!,
  applicationStatuses: undefined!,
  countries: undefined!,
  locationTypes: undefined!,
  companyTypes: undefined!,
  userTypes: undefined!,
  applicationServices: undefined!,
  carriers: undefined!,
  bitrixCarriers: undefined!,
  incotermsTypes: undefined!,

  // данные зависящие от компании
  settlementAccounts: undefined!,
  currencies: undefined!,

  roleUserActions: [
    {
      id: 1,
      name: "Просмотр",
    },
    {
      id: 2,
      name: "Редактирование",
    },
    {
      id: 3,
      name: "Назначать заявки",
    },
  ],
  roleUserActionSettings: [
    {
      id: 1,
      name: "Справочник стран",
      actions: [1, 2],
    },
    {
      id: 2,
      name: "Справочник пунктов",
      actions: [1, 2],
    },
    {
      id: 3,
      name: "Справочник коэффициентов",
      actions: [1, 2],
    },
    {
      id: 4,
      name: "Справочник валют",
      actions: [1],
    },
    {
      id: 5,
      name: "Справочник внешних пользователей",
      actions: [1, 2],
    },

    {
      id: 6,
      name: "Справочник внутренних пользователей",
      actions: [1, 2],
    },
    {
      id: 7,
      name: "Справочник маршрутов",
      actions: [1, 2],
    },
    {
      id: 8,
      name: "Заявки",
      actions: [3],
    },
  ],

  messageConfigShow: undefined!,
  waypointLocations: undefined!,
  borderCrossingLocations: undefined!,

  host,
  bitrixOptions,
  language: undefined!,

  // // @ts-expect-error
  // host: "BITRIX",
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    initialDataLoaded(state) {
      state.loading = false;
    },
    initialDataLoadedError(state) {
      state.initialLoadingError = true;
    },
    openAuthModal(state) {
      state.isAuthModalVisible = true;
    },
    closeAuthModal(state) {
      state.isAuthModalVisible = false;
    },
    openContactModal(state) {
      state.isContactModalVisible = true;
    },
    closeContactModal(state) {
      state.isContactModalVisible = false;
    },
    // eslint-disable-next-line no-unused-vars
    sendFeedbackForm(state, action: PayloadAction<ContactFormInputModel>) {
      state.isContactModalLoading = true;
    },
    sendFeedbackFormSuccess(state) {
      state.isContactModalLoading = false;
      state.isContactModalVisible = false;
    },
    sendFeedbackFormError(state) {
      state.isContactModalLoading = false;
    },
    globalSettingsLoaded(state, action: PayloadAction<GlobalSettingsModel>) {
      const {
        currencies,
        transportCharacteristics,
        transportationTypes,
        additionalJobs,
        documentTypes,
        conditionDeliveries,
        orderStatuses,
        countries,
        locationTypes,
        companyTypes,
        waypointLocations,
        userTypes,
        borderCrossingLocations,
        applicationServices,
        carriers,
        incotermsTypes,
        bitrixCarriers,
      } = action.payload;
      state.globalCurrencies = currencies;
      state.transportCharacteristics = transportCharacteristics;
      state.transportationTypes = transportationTypes;
      state.additionalJobs = additionalJobs;
      state.conditionDeliveries = conditionDeliveries;
      state.documentTypes = documentTypes;
      state.applicationStatuses = orderStatuses;
      state.countries = countries;
      state.locationTypes = locationTypes;
      state.companyTypes = companyTypes;
      state.waypointLocations = waypointLocations;
      state.userTypes = userTypes;
      state.borderCrossingLocations = borderCrossingLocations;
      state.applicationServices = applicationServices;
      state.carriers = carriers;
      state.incotermsTypes = incotermsTypes;
      state.bitrixCarriers = bitrixCarriers;
    },
    userSettingsLoaded(state, action: PayloadAction<UserSettingModel>) {
      const { settlementAccounts, currencies } = action.payload;
      state.settlementAccounts = settlementAccounts;
      state.currencies = currencies;
    },
    userLoaded(state, action: PayloadAction<UserInfoModel>) {
      state.userInfo = action.payload;
    },
    setMessageConfigShow(state, action: PayloadAction<MessageConfig>) {
      state.messageConfigShow = { ...action.payload };
    },
    changeContainerIssuingAdditionalJob(state, action: PayloadAction<AdditionalJobModel>) {
      const index = state.additionalJobs.findIndex((x) => x.id === action.payload.id);
      state.additionalJobs[index] = action.payload;
    },
    setBitrixOptions(
      state,
      action: PayloadAction<
        | {
            email: string;
            leadId: number;
            companyId: number;
          }
        | undefined
      >,
    ) {
      state.bitrixOptions = action.payload;
      if (action.payload) {
        state.host = "BITRIX";
      }
    },
    setLanguage(state, action: PayloadAction<SupportedLanguages>) {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AuthActions.logistLoginSuccess, (state, action) => {
      state.userInfo = action.payload;
    });
    builder.addCase(AuthActions.loginSuccess, (state, action) => {
      state.userInfo = action.payload;
      state.isAuthModalVisible = false;
    });
    builder.addCase(AuthActions.logoutSuccess, (state) => {
      state.userInfo = undefined!;
    });
  },
});

export default globalSlice;
