import React, { useCallback, useEffect } from "react";

// eslint-disable-next-line
import { useNavigate } from "react-router";

import { Form, Button, Space, Typography } from "antd";

import InputMask from "react-input-mask";

import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import { NumericFormat } from "react-number-format";
import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import AppSlice from "../../../../../App/reducer";

import { makeSelectRegistrationFormErrors } from "../../../../selectors";

import { Agreements } from "./styled";

import { RegisterFormProps, RegisterFormInputModel } from "./types";
import { phoneNumberRegExp } from "../../../../../../utils/regExps";
import AnimatedInput from "../../../../../../components/AnimatedInput";

function RegisterForm({ loadingData, onRegister, onOpenLoginView }: RegisterFormProps) {
  const { t } = useTranslation("translations");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formErrors = useSelector(makeSelectRegistrationFormErrors());

  useEffect(() => {
    if (formErrors) {
      const errors = [];

      if (formErrors.find((x) => x.field === "login" && x.errorCode === "3")) {
        errors.push({
          name: "login",
          errors: [t("identification_number_already_registered")],
        });
      }

      if (formErrors.find((x) => x.field === "email" && x.errorCode === "3")) {
        errors.push({
          name: "password",
          errors: [t("email_already_registered")],
        });
      }
      form.setFields(errors);
    }
  }, [formErrors, form, t]);

  const onFinish = ({ login, companyName, phoneNumber, email }: RegisterFormInputModel) => {
    onRegister({ login, companyName, phoneNumber, email });
  };

  const handleBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length >= 10 && e.target.value.length <= 12) {
        (async () => {
          try {
            const response = await fetch(`https://egrul.itsoft.ru/short_data/?${e.target.value}}`);
            const result: any = await response.json();
            form.setFields([
              {
                name: "companyName",
                value: result.full_name,
                errors: undefined,
              },
            ]);
          } catch (err) {
            console.log("No registered companies found");
          }
        })();
      }
    },
    [form],
  );

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="login"
        rules={[
          () => ({
            validator(_, value: string) {
              if (value && value.length >= 10 && value.length <= 12) {
                return Promise.resolve();
              }
              if (!value) {
                return Promise.reject(t("enter_identification_number"));
              }
              return Promise.reject(t("identification_number_length"));
            },
          }),
        ]}
      >
        <NumericFormat
          placeholder={t("identification_number")}
          allowNegative={false}
          decimalScale={0}
          allowLeadingZeros
          customInput={AnimatedInput}
          prefixIcon={<UserOutlined />}
          onBlur={handleBlur}
        />
      </Form.Item>

      <Form.Item name="companyName" rules={[{ required: true, message: t("enter_company_name")! }]}>
        <AnimatedInput placeholder={t("company_name")} />
      </Form.Item>

      <Form.Item
        name="phoneNumber"
        rules={[
          () => ({
            validator(_, value: string) {
              if (phoneNumberRegExp.test(value)) {
                return Promise.resolve();
              }
              if (!value) {
                return Promise.reject(t("enter_phone_number"));
              }
              return Promise.reject(t("enter_phone_number_in_format"));
            },
          }),
        ]}
      >
        <InputMask mask="+7 (999) 999 - 9999">
          <AnimatedInput placeholder={t("phone_number")} prefixIcon={<PhoneOutlined />} />
        </InputMask>
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          { required: true, message: t("enter_email")! },
          { type: "email", message: t("wrong_email")! },
        ]}
      >
        <AnimatedInput placeholder="Email" prefixIcon={<MailOutlined />} />
      </Form.Item>

      <Agreements
        onClick={() => {
          dispatch(AppSlice.actions.closeAuthModal());
          navigate("/privacy/some1");
        }}
      >
        {t("register_agreements_text")} <span>{t("policy_personal_date")}</span>
      </Agreements>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loadingData}>
          {t("register")}
        </Button>
      </Form.Item>

      <Space align="center" style={{ width: "100%", justifyContent: "center" }}>
        <Typography.Text>{t("is_already_registered")}</Typography.Text>
        <Button onClick={onOpenLoginView} type="text">
          {t("enter")}
        </Button>
      </Space>
    </Form>
  );
}

export default React.memo(RegisterForm);
