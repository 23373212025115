import { all, call, put, take, fork, select } from "redux-saga/effects";

import { PayloadAction } from "@reduxjs/toolkit";

import { globalHttpService } from "./apiService";

import slice from "../reducer";

import { generateUserInfo } from "../../../auth/utils";

import { MessagesConfig } from "../components/MessageProvider/config";

import { ContactFormInputModel } from "../../ContactModal/components/ContactForm/types";
import { makeSelectBitrixOptions, makeSelectHost } from "../selectors";
import { IAccountInfoDto } from "../../../dto/User/IAccountInfoDto";
import SettlementAccountDto from "../../../dto/Dictionaries/SettlementAccountDto";
import { AuthActions } from "../../../auth/actions";
import CurrencyDto from "../../../dto/Settings/CurrencyDto";

function* sendFeedbackFormWatcher() {
  while (true) {
    const { payload }: PayloadAction<ContactFormInputModel> = yield take(slice.actions.sendFeedbackForm.type);
    const { fullname, phoneNumber, email, message } = payload;

    const { success, reject } = yield call([globalHttpService, globalHttpService.SendFeedbackForm], fullname, phoneNumber, email, message);

    if (success) {
      yield put(slice.actions.sendFeedbackFormSuccess());
      yield put(slice.actions.setMessageConfigShow(MessagesConfig.sendFeedbackFormSuccess));
    }

    if (reject) {
      yield put(slice.actions.sendFeedbackFormError());
    }
  }
}

function* changeContainerIssuingAdditionalJobWatcher() {
  while (true) {
    const { payload }: ReturnType<typeof slice.actions.changeContainerIssuingAdditionalJob> = yield take(
      slice.actions.changeContainerIssuingAdditionalJob.type,
    );

    yield call([globalHttpService, globalHttpService.ChangeContainerIssuingAdditionalJobWatcher], payload);
  }
}

function* loadUserSettingsWorker() {
  const [{ success: settlementAccountsSuccess }, { success: currenciesSuccess }]: [
    { success: { payload: Array<SettlementAccountDto> } },
    { success: { payload: Array<CurrencyDto> } },
  ] = yield all([
    call([globalHttpService, globalHttpService.GetSettlementAccounts]),
    call([globalHttpService, globalHttpService.GetCurrencies]),
  ]);

  yield put(
    slice.actions.userSettingsLoaded({
      settlementAccounts: settlementAccountsSuccess.payload,
      currencies: currenciesSuccess.payload,
    }),
  );
}

function* loadGlobalSettingsWorker() {
  const [
    { success: settingsSuccess, reject: settingsError },
    { success: countriesSuccess },
    { success: middlePointSuccess },
    { success: borderCrossingSuccess },
    { success: applicationServicesSuccess },
    { success: carriersSuccess },
    { success: bitrixCarriersSuccess },
  ] = yield all([
    call([globalHttpService, globalHttpService.GetSettings]),
    call([globalHttpService, globalHttpService.LoadCountries]),
    call([globalHttpService, globalHttpService.LoadWaypointLocations]),
    call([globalHttpService, globalHttpService.LoadBorderCrossingLocations]),
    call([globalHttpService, globalHttpService.LoadApplicationServices]),
    call([globalHttpService, globalHttpService.GetCarriers]),
    call([globalHttpService, globalHttpService.GetBitrixCarriers]),
  ]);

  if (settingsSuccess) {
    yield put(
      slice.actions.globalSettingsLoaded({
        ...settingsSuccess.payload,
        countries: countriesSuccess.payload,
        waypointLocations: middlePointSuccess.payload,
        borderCrossingLocations: borderCrossingSuccess.payload,
        applicationServices: applicationServicesSuccess.payload,
        carriers: carriersSuccess.payload,
        bitrixCarriers: bitrixCarriersSuccess.payload,
      }),
    );
    yield put(slice.actions.initialDataLoaded());
  }

  if (settingsError) {
    yield put(slice.actions.initialDataLoadedError());
  }
}

function* loginSuccessWatcher() {
  while (true) {
    yield take(AuthActions.loginSuccess.type);

    yield call(loadUserSettingsWorker);
  }
}

export default function* globalSaga() {
  const host: ReturnType<ReturnType<typeof makeSelectHost>> = yield select(makeSelectHost());
  const bitrixOptions: ReturnType<ReturnType<typeof makeSelectBitrixOptions>> = yield select(makeSelectBitrixOptions());

  const { success: userSuccess }: { success: { payload: IAccountInfoDto }; reject: unknown } =
    host === "BITRIX" && bitrixOptions
      ? yield call([globalHttpService, globalHttpService.LoginBitrix], bitrixOptions.email)
      : yield call([globalHttpService, globalHttpService.GetUser]);

  if (userSuccess) {
    const userInfo = generateUserInfo(userSuccess.payload);
    yield put(slice.actions.userLoaded(userInfo));
    // сначала загружаем только настройки компании
    yield call(loadUserSettingsWorker);
  }

  // тут загружаем уже все остальные справочники и завершаем загрузку
  yield call(loadGlobalSettingsWorker);

  yield fork(loginSuccessWatcher);
  yield fork(sendFeedbackFormWatcher);
  yield fork(changeContainerIssuingAdditionalJobWatcher);
}
