import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import PaymentModel from "../../../viewModels/Payments/PaymentModel";
import SortObject from "../../../types/SortObject";
import { DealAccountModel } from "../../../viewModels/Deal/DealModel";
import { DealAccountsFiltersType } from "../../../components/V2Components/DealAccountFilters";
import { PaymentsFiltersType } from "../../../components/V2Components/PaymentFilters";
import AccountPaymentModel, { AccountPaymentCreateType } from "../../../viewModels/Deal/AccountPaymentModel";

const pageSizeLsKey = "accounts_table_pageSize";
const pageSizeFromLs = localStorage.getItem(pageSizeLsKey);

const initialState: {
  loading: boolean;
  loadingData: boolean;
  page: number;
  pageSize: number;
  accounts?: Array<DealAccountModel>;
  total?: number;
  sort?: SortObject;
  filters?: DealAccountsFiltersType;
  selectedAccountId?: number;

  paymentsInAccounts?: Array<PaymentModel>;

  attachPaymentModalOpen: boolean;
  paymentsLoading: boolean;
  paymentsLoadingData: boolean;
  paymentsPage: number;
  paymentsPageSize: number;
  payments?: Array<PaymentModel>;
  paymentsTotal?: number;
  paymentsSort?: SortObject;
  paymentsFilters?: PaymentsFiltersType;
  selectedPaymentId?: number;
  attachPaymentLoading: boolean;
} = {
  loading: true,
  loadingData: true,
  page: undefined!,
  pageSize: pageSizeFromLs ? +pageSizeFromLs : 10,

  attachPaymentModalOpen: false,
  paymentsLoading: true,
  paymentsLoadingData: true,
  paymentsPage: undefined!,
  paymentsPageSize: 10,
  attachPaymentLoading: false,
};

const accountantAccountsSlice = createSlice({
  name: "accountantAccounts",
  initialState,
  reducers: {
    loadAccounts(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadAccountsSuccess(state, action: PayloadAction<{ payments: Array<PaymentModel>; accounts: Array<DealAccountModel>; total: number }>) {
      const { payments, total, accounts } = action.payload;

      state.loading = false;
      state.loadingData = false;
      state.accounts = accounts;
      state.total = total;
      state.paymentsInAccounts = payments;
    },

    changeTable(state, action: PayloadAction<{ page: number; pageSize: number; sort?: SortObject; filters: DealAccountsFiltersType }>) {
      const { page, pageSize, sort, filters } = action.payload;
      state.loadingData = true;
      state.page = page;
      state.pageSize = pageSize;
      localStorage.setItem(pageSizeLsKey, `${pageSize}`);
      state.sort = sort;
      state.filters = filters;
    },

    openAttachPaymentModal(state, action: PayloadAction<{ filters: PaymentsFiltersType; accountId: number }>) {
      state.attachPaymentModalOpen = true;
      state.paymentsLoading = true;
      state.paymentsLoadingData = true;
      state.paymentsPage = 1;
      state.paymentsFilters = action.payload.filters;
      state.selectedAccountId = action.payload.accountId;
    },

    closeAttachPaymentModal(state) {
      if (!state.attachPaymentLoading) {
        state.attachPaymentModalOpen = false;
        state.payments = undefined;
        state.paymentsTotal = undefined;
        state.selectedAccountId = undefined;
        state.paymentsFilters = undefined;
        state.selectedPaymentId = undefined;
      }
    },

    loadPaymentsSuccess(state, action: PayloadAction<{ payments: Array<PaymentModel>; total: number }>) {
      const { payments, total } = action.payload;

      state.paymentsLoading = false;
      state.paymentsLoadingData = false;
      state.payments = payments;
      state.paymentsTotal = total;
    },

    changePaymentsTable(state, action: PayloadAction<{ page: number; pageSize: number; sort?: SortObject; filters: PaymentsFiltersType }>) {
      const { page, pageSize, sort, filters } = action.payload;
      state.paymentsLoadingData = true;
      state.paymentsPage = page;
      state.paymentsPageSize = pageSize;
      state.paymentsSort = sort;
      state.paymentsFilters = filters;
      state.selectedPaymentId = undefined;
    },

    changeSelectedPaymentId(state, action: PayloadAction<number | undefined>) {
      state.selectedPaymentId = action.payload;
    },

    // eslint-disable-next-line no-unused-vars
    attachPayment(state, action: PayloadAction<AccountPaymentCreateType>) {
      state.attachPaymentLoading = true;
    },

    attachPaymentSuccess(state, action: PayloadAction<{ payment: PaymentModel; account: DealAccountModel }>) {
      const { account, payment } = action.payload;

      state.attachPaymentLoading = false;
      state.attachPaymentModalOpen = false;
      state.payments = undefined;
      state.paymentsTotal = undefined;
      state.selectedAccountId = undefined;
      state.paymentsFilters = undefined;
      state.selectedPaymentId = undefined;

      if (state.accounts) {
        const index = state.accounts.findIndex((x) => x.id === account.id);
        if (index !== -1) {
          state.accounts[index] = account;
        }
      }

      if (state.paymentsInAccounts) {
        const index = state.paymentsInAccounts.findIndex((x) => x.id === payment.id);
        if (index !== -1) {
          state.paymentsInAccounts[index] = payment;
        } else {
          state.paymentsInAccounts.push(payment);
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    deleteAccountPayment(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },

    deleteAccountPaymentSuccess(state, action: PayloadAction<{ payment: PaymentModel; account: DealAccountModel }>) {
      const { account, payment } = action.payload;
      state.loadingData = false;

      if (state.accounts) {
        const index = state.accounts.findIndex((x) => x.id === account.id);
        if (index !== -1) {
          state.accounts[index] = account;
        }
      }

      if (state.paymentsInAccounts) {
        const index = state.paymentsInAccounts.findIndex((x) => x.id === payment.id);
        if (index !== -1) {
          state.paymentsInAccounts[index] = payment;
        } else {
          state.paymentsInAccounts.push(payment);
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    editAccountPayment(state, action: PayloadAction<AccountPaymentModel>) {},

    editAccountPaymentSuccess(state, action: PayloadAction<{ payment: PaymentModel; account: DealAccountModel }>) {
      const { account, payment } = action.payload;

      if (state.accounts) {
        const index = state.accounts.findIndex((x) => x.id === account.id);
        if (index !== -1) {
          state.accounts[index] = account;
        }
      }

      if (state.paymentsInAccounts) {
        const index = state.paymentsInAccounts.findIndex((x) => x.id === payment.id);
        if (index !== -1) {
          state.paymentsInAccounts[index] = payment;
        } else {
          state.paymentsInAccounts.push(payment);
        }
      }
    },
  },
});

export default accountantAccountsSlice;
