import React from "react";
import styled, { css } from "styled-components";

import { Input as AntInput, InputProps } from "antd";
import { TextAreaProps } from "antd/lib/input";

const SIZES = {
  small: {
    fontSize: "12px",
    labelFontSize: "12px",
    labelLeft: "8px",
    labelAnimatedFontSize: "10px",
    labelAnimationTop: "12px",
    padding: "16px 7px 0",
    prefixMargin: "4px",
  },
  middle: {
    fontSize: "14px",
    labelFontSize: "14px",
    labelLeft: "17px",
    labelAnimatedFontSize: "12px",
    labelAnimationTop: "14px",
    padding: "20px 16px 4px",
    prefixMargin: "8px",
  },
  large: {
    fontSize: "16px",
    labelFontSize: "16px",
    labelLeft: "17px",
    labelAnimatedFontSize: "14px",
    labelAnimationTop: "16px",
    padding: "25px 16px 7px",
    prefixMargin: "8px",
  },
};

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
`;

const Label = styled.span<{ $size: keyof typeof SIZES; $prefixWidth?: number; $hasPrefix?: boolean }>`
  position: absolute;
  font-size: ${({ $size }) => SIZES[$size].labelFontSize};
  left: ${({ $hasPrefix, $prefixWidth, $size }) =>
    $hasPrefix ? `calc(${SIZES[$size].labelLeft} + ${$prefixWidth}px + ${SIZES[$size].prefixMargin})` : SIZES[$size].labelLeft};
  top: 50%;
  transform: translate(0px, -50%);
  color: var(--color-gray-7);
  transition: 0.2s ease all;
  pointer-events: none;
  z-index: 1;
`;

const PrefixContainer = styled.div<{ $size: keyof typeof SIZES }>`
  position: absolute;
  left: ${({ $size }) => SIZES[$size].padding.split(" ")[1]};
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  pointer-events: none;
  font-size: ${({ $size }) => SIZES[$size].fontSize};
`;

type StyledInputProps = {
  $hasValue: boolean;
  $size: keyof typeof SIZES;
  $prefixWidth?: number;
  $hasPrefix?: boolean;
};

const styles = css<StyledInputProps>`
  background: var(--color-gray-3);
  width: 100%;
  outline: none;
  padding: ${({ $size }) => SIZES[$size].padding};
  padding-left: ${({ $hasPrefix, $prefixWidth, $size }) =>
    $hasPrefix
      ? `calc(${SIZES[$size].padding.split(" ")[1]} + ${$prefixWidth}px + ${SIZES[$size].prefixMargin})`
      : SIZES[$size].padding.split(" ")[1]};
  border-color: transparent;
  border-radius: 8px;
  font-size: ${({ $size }) => SIZES[$size].fontSize};

  :disabled {
    background: var(--color-gray-2);
    border-color: transparent;

    & + ${Label} {
      color: var(--color-gray-6);
    }
  }

  :disabled:hover {
    border-color: transparent;
  }

  :hover:not(:disabled) {
    background: var(--color-gray-4);
    border-color: transparent;
  }

  :focus {
    background: var(--color-gray-1);
    border-color: var(--color-main-100);
    box-shadow: none;

    ${({ $size }) =>
      css`
        & + ${Label} {
          font-size: ${SIZES[$size].labelAnimatedFontSize};
          top: ${SIZES[$size].labelAnimationTop};
        }
      `};
  }

  :focus:hover {
    background: var(--color-gray-1);
    border-color: var(--color-main-100);
  }

  &::placeholder {
    opacity: 0;
  }

  ${({ $hasValue, $size }) =>
    $hasValue &&
    css`
      & + ${Label} {
        font-size: ${SIZES[$size].labelAnimatedFontSize};
        top: ${SIZES[$size].labelAnimationTop};
      }
    `};
`;

const Input = styled(AntInput)<StyledInputProps>`
  ${styles}
`;

const TextArea = styled(AntInput.TextArea)<StyledInputProps>`
  ${styles}
`;

const AnimatedInput = React.memo(
  ({ size = "middle", placeholder, prefixIcon, ...rest }: Omit<InputProps, "prefix"> & { prefixIcon?: React.ReactNode }) => {
    const hasValue = !!rest.value || !!rest.defaultValue;

    const prefixRef = React.useRef<HTMLDivElement>(null);
    const [prefixWidth, setPrefixWidth] = React.useState(0);

    React.useLayoutEffect(() => {
      const measureWidth = () => {
        if (prefixRef.current) {
          const width = prefixRef.current.offsetWidth;
          setPrefixWidth(width);
        }
      };
      measureWidth();
      const rafId = requestAnimationFrame(() => {
        measureWidth();
      });

      return () => cancelAnimationFrame(rafId);
    }, [prefixIcon]);

    return (
      <Wrapper>
        {prefixIcon && (
          <PrefixContainer $size={size} ref={prefixRef}>
            {prefixIcon}
          </PrefixContainer>
        )}
        <Input {...rest} $hasValue={hasValue} $size={size} $prefixWidth={prefixWidth} $hasPrefix={!!prefixIcon} />
        <Label $size={size} $prefixWidth={prefixWidth} $hasPrefix={!!prefixIcon}>
          {placeholder}
        </Label>
      </Wrapper>
    );
  },
);

export const AnimatedTextArea = React.memo(({ size = "middle", placeholder, ...rest }: TextAreaProps) => {
  const hasValue = !!rest.value || !!rest.defaultValue;

  return (
    <Wrapper>
      <TextArea {...rest} $hasValue={hasValue} $size={size} autoComplete="off" />
      <Label $size={size}>{placeholder}</Label>
    </Wrapper>
  );
});

export default AnimatedInput;
