import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import PaymentModel, { PaymentCreateType, PaymentUpdateType } from "../../../viewModels/Payments/PaymentModel";
import SortObject from "../../../types/SortObject";
import { DealAccountModel } from "../../../viewModels/Deal/DealModel";
import AccountPaymentModel, { AccountPaymentCreateType } from "../../../viewModels/Deal/AccountPaymentModel";
import { DealAccountsFiltersType } from "../../../components/V2Components/DealAccountFilters";
import { PaymentsFiltersType } from "../../../components/V2Components/PaymentFilters";

const pageSizeLsKey = "payments_table_pageSize";
const pageSizeFromLs = localStorage.getItem(pageSizeLsKey);

const initialState: {
  loading: boolean;
  loadingData: boolean;
  page: number;
  pageSize: number;
  payments?: Array<PaymentModel>;
  total?: number;
  sort?: SortObject;
  filters?: PaymentsFiltersType;

  selectedPaymentId?: number;

  accountsInPayments?: Array<DealAccountModel>;

  distributePaymentModalOpen: boolean;
  accountsLoading: boolean;
  accountsLoadingData: boolean;
  accountsPage: number;
  accountsPageSize: number;
  accounts?: Array<DealAccountModel>;
  accountsTotal?: number;
  accountsSort?: SortObject;
  accountsFilters?: DealAccountsFiltersType;
  selectedAccountId?: number;

  distributePaymentLoading: boolean;
} = {
  loading: true,
  loadingData: true,
  page: undefined!,
  pageSize: pageSizeFromLs ? +pageSizeFromLs : 10,

  distributePaymentModalOpen: false,
  accountsLoading: true,
  accountsLoadingData: true,
  accountsPage: undefined!,
  accountsPageSize: 10,

  distributePaymentLoading: false,
};

const accountantPaymentsSlice = createSlice({
  name: "accountantPayments",
  initialState,
  reducers: {
    loadPayments(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadPaymentsSuccess(state, action: PayloadAction<{ payments: Array<PaymentModel>; accounts: Array<DealAccountModel>; total: number }>) {
      const { payments, total, accounts } = action.payload;

      state.loading = false;
      state.loadingData = false;
      state.payments = payments;
      state.total = total;
      state.accountsInPayments = accounts;
    },

    changeTable(state, action: PayloadAction<{ page: number; pageSize: number; sort?: SortObject; filters: PaymentsFiltersType }>) {
      const { page, pageSize, sort, filters } = action.payload;
      state.loadingData = true;
      state.page = page;
      state.pageSize = pageSize;
      localStorage.setItem(pageSizeLsKey, `${pageSize}`);
      state.sort = sort;
      state.filters = filters;
    },

    openDistributePaymentModal(state, action: PayloadAction<{ filters: DealAccountsFiltersType; paymentId: number }>) {
      state.distributePaymentModalOpen = true;
      state.accountsLoading = true;
      state.accountsLoadingData = true;
      state.accountsPage = 1;
      state.accountsFilters = action.payload.filters;
      state.selectedPaymentId = action.payload.paymentId;
    },

    closeDistributePaymentModal(state) {
      if (!state.distributePaymentLoading) {
        state.distributePaymentModalOpen = false;
        state.accounts = undefined;
        state.accountsTotal = undefined;
        state.selectedPaymentId = undefined;
        state.accountsFilters = undefined;
        state.selectedAccountId = undefined;
      }
    },

    loadAccountsSuccess(state, action: PayloadAction<{ accounts: Array<DealAccountModel>; total: number }>) {
      const { accounts, total } = action.payload;

      state.accountsLoading = false;
      state.accountsLoadingData = false;
      state.accounts = accounts;
      state.accountsTotal = total;
    },

    changeAccountsTable(
      state,
      action: PayloadAction<{ page: number; pageSize: number; sort?: SortObject; filters: DealAccountsFiltersType }>,
    ) {
      const { page, pageSize, sort, filters } = action.payload;
      state.accountsLoadingData = true;
      state.accountsPage = page;
      state.accountsPageSize = pageSize;
      state.accountsSort = sort;
      state.accountsFilters = filters;
      state.selectedAccountId = undefined;
    },

    changeSelectedAccountId(state, action: PayloadAction<number | undefined>) {
      state.selectedAccountId = action.payload;
    },

    // eslint-disable-next-line no-unused-vars
    distributePayment(state, action: PayloadAction<AccountPaymentCreateType>) {
      state.distributePaymentLoading = true;
    },

    distributePaymentSuccess(state, action: PayloadAction<{ payment: PaymentModel; account: DealAccountModel }>) {
      const { account, payment } = action.payload;

      state.distributePaymentLoading = false;
      state.distributePaymentModalOpen = false;
      state.accounts = undefined;
      state.accountsTotal = undefined;
      state.selectedPaymentId = undefined;
      state.accountsFilters = undefined;
      state.selectedAccountId = undefined;

      if (state.payments) {
        const index = state.payments.findIndex((x) => x.id === payment.id);
        if (index !== -1) {
          state.payments[index] = payment;
        }
      }

      if (state.accountsInPayments) {
        const index = state.accountsInPayments.findIndex((x) => x.id === account.id);
        if (index !== -1) {
          state.accountsInPayments[index] = account;
        } else {
          state.accountsInPayments.push(account);
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    deleteAccountPayment(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },

    deleteAccountPaymentSuccess(state, action: PayloadAction<{ payment: PaymentModel; account: DealAccountModel }>) {
      const { account, payment } = action.payload;
      state.loadingData = false;

      if (state.payments) {
        const index = state.payments.findIndex((x) => x.id === payment.id);
        if (index !== -1) {
          state.payments[index] = payment;
        }
      }

      if (state.accountsInPayments) {
        const index = state.accountsInPayments.findIndex((x) => x.id === account.id);
        if (index !== -1) {
          state.accountsInPayments[index] = account;
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    editAccountPayment(state, action: PayloadAction<AccountPaymentModel>) {},

    editAccountPaymentSuccess(state, action: PayloadAction<{ payment: PaymentModel; account: DealAccountModel }>) {
      const { account, payment } = action.payload;

      if (state.payments) {
        const index = state.payments.findIndex((x) => x.id === payment.id);
        if (index !== -1) {
          state.payments[index] = payment;
        }
      }

      if (state.accountsInPayments) {
        const index = state.accountsInPayments.findIndex((x) => x.id === account.id);
        if (index !== -1) {
          state.accountsInPayments[index] = account;
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    createPayment(state, action: PayloadAction<PaymentCreateType>) {
      state.loadingData = true;
    },

    // eslint-disable-next-line no-unused-vars
    updatePayment(state, action: PayloadAction<PaymentUpdateType>) {
      state.loadingData = true;
    },
    updatePaymentSucess(state, action: PayloadAction<PaymentModel>) {
      state.loadingData = false;

      if (state.payments) {
        const index = state.payments.findIndex((x) => x.id === action.payload.id);
        if (index !== -1) {
          state.payments[index] = action.payload;
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    deletePayment(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },
  },
});

export default accountantPaymentsSlice;
