import React, { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Typography } from "antd";
import { AuthActions } from "../../../../auth/actions";

import { makeSelectIsRestorePasswordLoading } from "../../selectors";

import RestorePasswordForm from "./components/RestorePasswordForm";

import { RestorePasswordInputModel } from "../../../../viewModels/Auth/RestorePasswordInputModel";

function RestorePasswordView() {
  const { t } = useTranslation("translations");
  const dispatch = useDispatch();
  const isRestorePasswordLoading = useSelector(makeSelectIsRestorePasswordLoading());

  const handleRestorePassword = useCallback(
    (restorePasswordModel: RestorePasswordInputModel) => {
      dispatch(AuthActions.restorePassword(restorePasswordModel));
    },
    [dispatch],
  );

  return (
    <>
      <Typography.Title level={5} style={{ marginBottom: 24 }}>
        {t("enter_email_using_on_registration")}
      </Typography.Title>
      <RestorePasswordForm loadingData={isRestorePasswordLoading} onRestorePassword={handleRestorePassword} />
    </>
  );
}

export default React.memo(RestorePasswordView);
