import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import SortObject from "../../../types/SortObject";
import AccountClosingDocumentModel from "../../../viewModels/Deal/AccountClosingDocumentModel";
import { AccountClosingDocumentFiltersType } from "../../../components/V2Components/AccountClosingDocumentFilters";
import { DealAccountModel } from "../../../viewModels/Deal/DealModel";

const pageSizeLsKey = "closing_documents_table_pageSize";
const pageSizeFromLs = localStorage.getItem(pageSizeLsKey);

const initialState: {
  loading: boolean;
  loadingData: boolean;
  page: number;
  pageSize: number;
  closingDocuments?: Array<AccountClosingDocumentModel>;
  total?: number;
  sort?: SortObject;
  filters?: AccountClosingDocumentFiltersType;
  accountsInDocuments: Array<DealAccountModel>;
} = {
  loading: true,
  loadingData: true,
  page: undefined!,
  pageSize: pageSizeFromLs ? +pageSizeFromLs : 10,

  accountsInDocuments: [],
};

const accountantAcountClosingDocumentsSlice = createSlice({
  name: "accountantAcountClosingDocuments",
  initialState,
  reducers: {
    loadClosingDocuments(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadClosingDocumentsSuccess(
      state,
      action: PayloadAction<{ closingDocuments: Array<AccountClosingDocumentModel>; total: number; accounts: Array<DealAccountModel> }>,
    ) {
      const { total, closingDocuments, accounts } = action.payload;

      state.loading = false;
      state.loadingData = false;
      state.closingDocuments = closingDocuments;
      state.total = total;
      state.accountsInDocuments = accounts;
    },

    changeTable(
      state,
      action: PayloadAction<{ page: number; pageSize: number; sort?: SortObject; filters: AccountClosingDocumentFiltersType }>,
    ) {
      const { page, pageSize, sort, filters } = action.payload;
      state.loadingData = true;
      state.page = page;
      state.pageSize = pageSize;
      localStorage.setItem(pageSizeLsKey, `${pageSize}`);
      state.sort = sort;
      state.filters = filters;
    },
  },
});

export default accountantAcountClosingDocumentsSlice;
