import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import SettlementAccountModel, { SettlementAccountCreateType } from "../../../viewModels/Dictionaries/SettlementAccountModel";
import { SettlementAccountsFiltersType } from "../../../components/V2Components/SettlementAccountFilters";

const initialState: {
  loading: boolean;
  loadingData: boolean;
  settlementAccounts?: Array<SettlementAccountModel>;
  page: number;
  pageSize: number;
  total?: number;
  filters?: SettlementAccountsFiltersType;

  settlementAccountIdToEdit?: number;
  isEditSettlementAccountDrawerLoading: boolean;
  isEditSettlementAccountDrawerOpen: boolean;
} = {
  loading: true,
  loadingData: true,

  total: undefined!,
  pageSize: 10,
  page: 1,

  isEditSettlementAccountDrawerLoading: false,
  isEditSettlementAccountDrawerOpen: false,
};

const settlementAccountsSlice = createSlice({
  name: "settlementAccounts",
  initialState,
  reducers: {
    loadSettlementAccounts(state) {
      state.loadingData = true;
      state.page = 1;
    },
    loadSettlementAccountsSuccess(state, action: PayloadAction<{ settlementAccounts?: Array<SettlementAccountModel>; total: number }>) {
      const { settlementAccounts, total } = action.payload;

      state.loading = false;
      state.loadingData = false;
      state.settlementAccounts = settlementAccounts;
      state.total = total;
    },
    changeTable(state, action: PayloadAction<{ page: number; pageSize: number; filters: SettlementAccountsFiltersType }>) {
      const { page, pageSize, filters } = action.payload;
      state.loadingData = true;
      state.page = page;
      state.pageSize = pageSize;
      state.filters = filters;
    },
    openEditSettlementAccountDrawer(state, action: PayloadAction<number | undefined>) {
      state.settlementAccountIdToEdit = action.payload;
      state.isEditSettlementAccountDrawerOpen = true;
    },
    closeEditSettlementAccountDrawer(state) {
      if (!state.isEditSettlementAccountDrawerLoading) {
        state.settlementAccountIdToEdit = undefined;
        state.isEditSettlementAccountDrawerOpen = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    createSettlementAccount(state, action: PayloadAction<SettlementAccountCreateType>) {
      state.isEditSettlementAccountDrawerLoading = true;
    },
    createSettlementAccountSuccess(state) {
      state.isEditSettlementAccountDrawerLoading = false;
      state.isEditSettlementAccountDrawerOpen = false;
    },
    // eslint-disable-next-line no-unused-vars
    updateSettlementAccount(state, action: PayloadAction<SettlementAccountModel>) {
      state.isEditSettlementAccountDrawerLoading = true;
    },
    updateSettlementAccountSuccess(state, action: PayloadAction<SettlementAccountModel>) {
      state.isEditSettlementAccountDrawerLoading = false;
      state.isEditSettlementAccountDrawerOpen = false;
      if (state.settlementAccounts) {
        const index = state.settlementAccounts.findIndex((x) => x.id === action.payload.id);
        state.settlementAccounts[index] = action.payload;
      }
    },
    // eslint-disable-next-line no-unused-vars
    deleteSettlementAccount(state, action: PayloadAction<number>) {
      state.loadingData = true;
    },
  },
});

export default settlementAccountsSlice;
